(function () {    
$( document ).ready(function() {

        var kassencount = $('#kassen-cards div').length;
        var IMG_WIDTH = 320;
        $('#kassen-cards').css("width", kassencount * IMG_WIDTH);

        var currentImg = 0;
        var maxImages = kassencount;
        var speed = 500;

        var kassencards;

        var swipeOptions = {
            triggerOnTouchEnd: true,
            swipeStatus: swipeStatus,
            allowPageScroll: "none",
            threshold: 40
        };

        $(function () {
            kassencards = $("#kassen-cards");
            kassencards.swipe(swipeOptions);
        });


        $("#arrow-left-kasse").click(function(){
            // alert("left");
            previousKassenImage();
        });

        $("#arrow-right-kasse").click(function(){
            // alert("right");
            nextKassenImage();
        });

        /**
         * Catch each phase of the swipe.
         * move : we drag the div
         * cancel : we animate back to where we were
         * end : we animate to the next image
         */
        function swipeStatus(event, phase, direction, distance) {
            //If we are moving before swipe, and we are going L or R in X mode, or U or D in Y mode then drag.
            if (phase == "move" && (direction == "left" || direction == "right")) {
                var duration = 0;

                if (direction == "left") {
                    scrollKassenImages((IMG_WIDTH * currentImg) + distance, duration);
                } else if (direction == "right") {
                    scrollKassenImages((IMG_WIDTH * currentImg) - distance, duration);
                }

            } else if (phase == "cancel") {
                scrollKassenImages(IMG_WIDTH * currentImg, speed);
            } else if (phase == "end") {
                if (direction == "right") {
                    previousKassenImage();
                } else if (direction == "left") {
                    nextKassenImage();
                }
            }
        }

        function previousKassenImage() {
            currentImg = Math.max(currentImg - 1, 0);
            scrollKassenImages(IMG_WIDTH * currentImg, speed);
        }

        function nextKassenImage() {
            currentImg = Math.min(currentImg + 1, maxImages - 1);
            scrollKassenImages(IMG_WIDTH * currentImg, speed);
        }

        /**
         * Manually update the position of the imgs on drag
         */
        function scrollKassenImages(distance, duration) {
            kassencards.css("transition-duration", (duration / 1000).toFixed(1) + "s");

            //inverse the number we set in the css
            var value = (distance < 0 ? "" : "-") + Math.abs(distance).toString();
            kassencards.css("transform", "translate(" + value + "px,0)");
        }
});
})();

